import { StyleSheet, Text, View, ScrollView, FlatList, Modal, SafeAreaView, TextInput, TouchableOpacity, Alert} from 'react-native';
import { useEffect, useState } from 'react';
import { Icon } from '@rneui/base';
import { Button, Divider } from '@rneui/themed';
import { db, auth } from '../firebaseConfig';
import { onValue, push, ref, set, get, update } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { isEmpty, isEqual, merge } from 'lodash';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { en, registerTranslation } from 'react-native-paper-dates';
import TaskItem from './TaskItem';
import { AntDesign } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import { SwipeRow } from 'react-native-swipe-list-view';
import { Octicons } from '@expo/vector-icons';


export default function TaskScreen() {
    const [tasks, setTask] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [input, setInput] = useState("")
    const [existing, setExisting] = useState(false);
    const [extraData, setExtraData] = useState(new Date())
    const [curVal, setCurVal] = useState(0);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [timePickerVisible, setTimePickerVisible] = useState(false);
    const [chosenDate, setChosenDate] = useState(null);
    const [chosenTime, setChosenTime] = useState(null);
    const [tasksRef, setTasksRef] = useState(null);
    
    registerTranslation('en', en);

    //toggles input modal
    const toggleModalVisibility = () => {
        setModalVisible(!isModalVisible);
        setChosenTime(null);
        setChosenDate(null);
    }
    
    //updates local tasks to database
    const updateTaskData = () => {
        // console.log("tasks ref: " + tasksRef);
        if(tasksRef != null) {
            set(tasksRef, {
                items: tasks
            });
        }
        
    }

    function deleteTask(index) {
        const newArray = [...tasks];
        newArray.splice(index, 1);
        setTimeout(() => {
            setTask(newArray);
        }, 1000);
        
    }
    
    //synchronizes database tasks with local tasks
    function getUserTasks(snapshot) {
        let importedTasks = [];
        if(snapshot.val()) {
            snapshot.val().items.forEach((element) => importedTasks.push(element));
        }
        // console.log(isEqual(tasks, importedTasks));
        if(!isEqual(tasks, importedTasks)) {
            setTask(merge(importedTasks, tasks));
        }
        // console.log(tasks);
    }


    //sets database reference based on change in auth state
    function authListener(user) {
        if (user) {
            // console.log("task screen logged in " + user.uid);
            setTasksRef(ref(db, `tasks/${user.uid}`));
        } else {
            // console.log("task screen logged out");
            setTasksRef(null);
        }
      }

    
    //set listener for authentication state
    useEffect(() => {
        onAuthStateChanged(auth, authListener);
      }, []);

    
    //set listener for tasks array
    useEffect(() => {
        console.log("task changed");
        updateTaskData();
    }, [tasks]);

    //Set listener for user data
    useEffect(() => {
        if(tasksRef != null) {
            // console.log("loading tasks from database");
            onValue(tasksRef, getUserTasks);
        }
    }, [tasksRef]);

    
    const submitTask = () => {

        if(existing == true) {
            tasks[curVal].name = input;
            setTask(tasks);
            setExisting(false);
        }

        else{
            if(chosenDate) {
                setTask(tasks.concat([{name: input, date: chosenDate.toString(), time: chosenTime, selected: false}]));
            } else {
                setTask(tasks.concat([{name: input, date: null, time: chosenTime, selected: false}]));
            }
            
            }

        setInput("");
        toggleModalVisibility();
        // setExtraData(new Date());
        
    }

    const editTask = (index) => {
        setCurVal(index);
        setExisting(true);
        toggleModalVisibility();
    }

    const get12Hour = (hours) => {
        if(hours == 12) {
            return [hours, "PM"];
        }
        if(hours == 24) {
            return [hours, "AM"];
        }
        if(hours < 12) {
            return [hours, "AM"];
        }
        if(hours > 12) {
            return [hours%12, "PM"];
        }
    }

    const taskItem = (item, index) => {
        item.selected = false;
        if (item.time || item.date) {
            if(item.time && item.date) {
                return (
                    <View style={{flex: 1}}>
                        <View style={{flex: 1, flexDirection: "row"}}>

                            <TouchableOpacity onPress={() => {deleteTask(index)}} style={{opacity: 0.6, alignSelf: "center", paddingLeft: 10}}>
                                {item.selected ? (
                                <Octicons name="check-circle-fill" size={20} color="#bda57e" />
                                ) : (
                                <Octicons name="circle" size={20} color="white" />
                                )}
                            
                            </TouchableOpacity>

                            <View style={{flex: 1, paddingTop: 10, paddingHorizontal: 5}}>
                            <TouchableOpacity style={{}} onPress={() => editTask(index)}>
                                <View style={{flex: 1, borderRadius: 5}}>
                                    <View style={{flex: 1, justifyContent: "center", paddingLeft: 0, borderRadius: 5}}>
                                        <Text style={styles.listItem}>{item.name}</Text>
                                    </View>
                                    <View style={{flex: 1, flexDirection: "row", paddingLeft: 0}}>
                                            <Text style={styles.listData}>{item.date.toString().slice(4, 10)}</Text>
                                            <Text> </Text>
                                            <Text style={styles.listData}>{get12Hour(item.time.hours)[0] + ":" + item.time.minutes.toString().padStart(2, "0") + " " + get12Hour(item.time.hours)[1]}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                            </View>
                        </View>

                        <Divider color={"white"} inset={false} insetType='left' style={{opacity: 0.2, left: 40}} />
                    </View>
                );
            } if (item.time) {
                return (
                    <View style={{flex: 1}}>
                        <View style={{flex: 1, flexDirection: "row"}}>

                            <TouchableOpacity onPress={() => {deleteTask(index)}} style={{opacity: 0.6, alignSelf: "center", paddingLeft: 10}}>
                                {item.selected ? (
                                <Octicons name="check-circle-fill" size={20} color="#bda57e" />
                                ) : (
                                <Octicons name="circle" size={20} color="white" />
                                )}
                            
                            </TouchableOpacity>
                    <View style={{flex: 1, paddingTop: 10, paddingHorizontal: 5}}>
                    <TouchableOpacity style={{}} onPress={() => editTask(index)}>
                        <View style={{flex: 1, borderRadius: 5}}>
                            <View style={{flex: 1, justifyContent: "center", paddingLeft: 0, borderRadius: 5}}>
                                <Text style={styles.listItem}>{item.name}</Text>
                            </View>
                            <View style={{flex: 1, flexDirection: "row", paddingLeft: 0}}>
                                    <Text style={styles.listData}>{get12Hour(item.time.hours)[0] + ":" + item.time.minutes.toString().padStart(2, "0") + " " + get12Hour(item.time.hours)[1]}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    </View>
                    </View>
                    <Divider color={"white"} inset={false} insetType='left' style={{opacity: 0.2, left: 40}} />
                    </View>
                );
            } if (item.date) {
                return (
                    <View style={{flex: 1}}>
                    <View style={{flex: 1, flexDirection: "row"}}>

                        <TouchableOpacity onPress={() => {deleteTask(index)}} style={{opacity: 0.6, alignSelf: "center", paddingLeft: 10}}>
                            {item.selected ? (
                            <Octicons name="check-circle-fill" size={20} color="#bda57e" />
                            ) : (
                            <Octicons name="circle" size={20} color="white" />
                            )}
                        
                        </TouchableOpacity>
                    <View style={{flex: 1, paddingTop: 10, paddingHorizontal: 5}}>
                    <TouchableOpacity style={{}} onPress={() => editTask(index)}>
                        <View style={{flex: 1, borderRadius: 5}}>
                            <View style={{flex: 1, justifyContent: "center", paddingLeft: 0, borderRadius: 5}}>
                                <Text style={styles.listItem}>{item.name}</Text>
                            </View>
                            <View style={{flex: 1, flexDirection: "row", paddingLeft:0}}>
                                    <Text style={styles.listData}>{item.date.toString().slice(4, 10)}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    </View>
                    </View>
                    <Divider color={"white"} inset={false} insetType='left' style={{opacity: 0.2, left: 40}} />
                    </View>
                );
            }
        }
        else {
            return (
                <View style={{flex: 1}}>
                <View style={{flex: 1, flexDirection: "row"}}>
                    <TouchableOpacity onPress={() => {deleteTask(index)}} style={{top: 5, opacity: 0.6, alignSelf: "center", paddingLeft: 10}}>
                        {item.selected ? (
                        <Octicons name="check-circle-fill" size={20} color="white" />
                        ) : (
                        <Octicons name="circle" size={20} color="white" />
                        )}
                    </TouchableOpacity>

                <View style={{flex: 1, paddingTop: 10, paddingHorizontal: 5, justifyContent: "center"}}>
                    <TouchableOpacity style={{}} onPress={() => editTask(index)}>
                        <View style={{flex: 1, borderRadius: 5}}>
                            <View style={{flex: 1, justifyContent: "center", paddingLeft: 0, borderRadius: 5}}>
                                <Text style={styles.listItem}>{item.name}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    </View>                           
                </View>
                <Divider color={"white"} inset={false} insetType='left' style={{opacity: 0.2, left: 40}} />
                </View>
            );
        }
    }

    const renderTask = ({item, index}) => {
        return(
            taskItem(item, index)
        );
    }

    const toggleDatePickerVisibility = () => {
        setPickerVisible(!pickerVisible);
    }

    const toggleTimePickerVisibility = () => {
        setTimePickerVisible(!timePickerVisible);
    }

    const onSubmitDate = (date) => {
        const dateString = date.date.toString();
        const year = dateString.slice(0, 4);
        var newDate = new Date(dateString);
        setChosenDate(newDate);
        toggleDatePickerVisibility();
    }

    const onSubmitTime = (time) => {
        setChosenTime(time);
        toggleTimePickerVisibility();
    }

    return (
        <SafeAreaView style={{flex: 1}}>
        <View style={{flex: 1, backgroundColor: "#212120"}}> 
            <View style={{paddingLeft: 15, paddingTop: 20, paddingBottom: 10}}>
                <Text style={{color: "white", fontSize: 30}}>
                    {tasksRef ? (
                        <>{auth.currentUser.displayName + "'s Tasks"}</>
                    ) : (
                        <>Tasks</>
                    )}
                </Text>
            </View>

                <Modal animationType="slide"
                    transparent={true}
                    visible = {isModalVisible}
                    presentationStyle="overFullScreen" 
                    >
                    {/* <View style={{flex: 1}}> */}
                    <BlurView intensity={20} style={{flex: 1}}>
                    <View style={{flex: 1}}></View>
                    <View style={{flex: 5, alignSelf: "center", justifyContent: "flex-start", backgroundColor: '#3a3b3d', paddingTop: 30, paddingHorizontal: 20, borderRadius: 20,
                                width: '90%'}}>
                            <View style={{top: 20}}>
                            <TextInput style={{color: "white", padding: 20, borderColor: "white", borderRadius: 10, backgroundColor: "gray"
                                                }} placeholder={"Task Name"} value={input} onChangeText={setInput} onSubmitEditing={submitTask} />
                            </View>

                            {/* Date Picker */}                        
                            <View style={{top: 30}}>
                            <TouchableOpacity style={{padding: 20}} onPress={toggleDatePickerVisibility}> 
                                {chosenDate ? (
                                    <View style={{flex: 1, flexDirection: "row"}}>
                                        <View style={{flex: 1, flexDirection: "row"}}>
                                            <AntDesign name="calendar" size={24} color="white" />
                                            <View style={{backgroundColor: "#5e5d5d", borderRadius: 10, width: 200, height: 28, justifyContent: "center", left: 15}}>
                                                <Text style={{textAlign: "center", color: "white", fontSize: 15}}> {chosenDate.toDateString().slice(4)} </Text>
                                            </View>
                                        </View>

                                        <View style={{flex: 2}}></View>
                                        
                                    </View>
                                    
                                ) : (
                                    <View style={{flex: 1, flexDirection: "row"}}>
                                        <View style={{flex: 1, flexDirection: "row"}}>
                                            <AntDesign name="calendar" size={24} color="white" />
                                            <View style={{backgroundColor: "#5e5d5d", borderRadius: 10, width: 200, height: 28, justifyContent: "center", left: 15}}>
                                                <Text style={{textAlign: "center", color: "white", fontSize: 15}}> {"Choose a date"} </Text>
                                            </View>
                                        </View>

                                        <View style={{flex: 2}}></View>
                                        
                                    </View>
                                )}
                            </TouchableOpacity>
                            </View>


                            {/* Time Picker */}
                            <View style={{top: 20}}>
                            <TouchableOpacity style={{padding: 20}} onPress={toggleTimePickerVisibility}> 
                                {/* <Text style={styles.listItem}> {chosenTime.hours + ":" + chosenTime.minutes} </Text> */}
                                {chosenTime ? (
                                    <View style={{flex: 1, flexDirection: "row"}}>
                                        <View style={{flex: 1, flexDirection: "row"}}>
                                            <AntDesign name="clockcircleo" size={24} color="white" />
                                            <View style={{backgroundColor: "#5e5d5d", borderRadius: 10, width: 200, height: 28, justifyContent: "center", left: 15}}>
                                                <Text style={{textAlign: "center", color: "white", fontSize: 15}}> {chosenTime.hours + ":" + chosenTime.minutes.toString().padStart(2, "0")} </Text>
                                            </View>
                                        </View>

                                        <View style={{flex: 2}}></View>
                
                                    </View>
                                    
                                ) : (
                                    <View style={{flex: 1, flexDirection: "row"}}>
                                        <View style={{flex: 1, flexDirection: "row"}}>
                                            <AntDesign name="clockcircleo" size={24} color="white" />
                                            <View style={{backgroundColor: "#5e5d5d", borderRadius: 10, width: 200, height: 28, justifyContent: "center", left: 15}}>
                                                <Text style={{textAlign: "center", color: "white", fontSize: 15}}> {"Choose a time"} </Text>
                                            </View>
                                        </View>

                                        <View style={{flex: 2}}></View>
                
                                    </View>
                                )}
                            </TouchableOpacity>
                            </View>

                            <DatePickerModal 
                                locale="en"
                                mode="single"
                                presentationStyle='pageSheet'
                                visible={pickerVisible}
                                onDismiss={toggleDatePickerVisibility}
                                date={chosenDate}
                                onConfirm={onSubmitDate}
                            />

                            <TimePickerModal
                                visible={timePickerVisible}
                                onDismiss={toggleTimePickerVisibility}
                                presentationStyle='pageSheet'
                                onConfirm={onSubmitTime}
                                hours={new Date().getHours() + 1}
                                minutes={0}
                                use24HourClock={false}
                            />

                            {/* <DateTimePickerModal
                                isVisible={timePickerVisible}
                                mode="time"
                                date={chosenTime}
                                onConfirm={onSubmitTime}
                                onCancel={toggleTimePickerVisibility}/> */}

                            <View style={{bottom: 10, right: 10, position: "absolute"}}>
                                <Button title="Add" onPress={submitTask} type="clear">
                                    <Entypo name="add-to-list" size={30} color="white" />
                                </Button>
                            </View>
                            <View style={{top: 10, right: 10, position: "absolute"}}>
                                <Button onPress={toggleModalVisibility} type="clear"> 
                                    <AntDesign name="close" size={24} color="white" />
                                </Button>
                            </View>
                    </View>
                    <View style={{flex: 1}}></View>
                    </BlurView>
                    {/* </View> */}
                    
                </Modal>


            <View style={{flex: 1}}> 
                <View style={{flex: 1}}>
                <FlatList 
                data = {tasks}
                extraData={extraData}
                renderItem={renderTask}
                />
                </View>
                <View style={{paddingVertical: 20, paddingHorizontal: 10, justifyContent: "center"}}>
                    {/* <TextInput style={{color: "white"}} value={input} onChangeText={setInput} onSubmitEditing={submitTask} blurOnSubmit={true} /> */}
                    <TouchableOpacity style={{}} onPress={toggleModalVisibility}>
                        <Text style={styles.newItem}>Add item . . .</Text>
                    </TouchableOpacity>
                </View>
                
            </View>
            {/* <View style={{position: "absolute", bottom: 10, right: 10}}>
                <FAB onPress={toggleModalVisibility} size="large" icon={{name: "add", color: "white"}}/>
            </View> */}


        </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    popup:{
        backgroundColor: "808080",
    },
    listItem:{
        fontSize: 20,
        color: "white",
        alignSelf: "flex-start",
        paddingLeft: 10,
        paddingVertical: 5
    },
    listData:{
        fontSize: 15,
        color: "white",
        textAlign: "center",
        backgroundColor: "#424040",
        borderRadius: 5,
        alignSelf: "center",
        paddingHorizontal: 10,
        opacity: 0.8,
        left: 10,
        bottom: 2

    },
    newItem:{
        fontSize: 15,
        color: "white",
        opacity: 0.4,
        
    }

}
);