import { DarkTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StyleSheet, Text, View, Button, Alert } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TaskScreen from './ui/TaskScreen.jsx';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import Ionicons from '@expo/vector-icons/Ionicons.js'
import AccountSettings from './ui/AccountSettings.jsx';
import AuthScreen from './ui/AuthScreen.jsx';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig.js';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const Stack = createNativeStackNavigator();
let signedIn = false;

const getIsSignedIn = () => {
  return signedIn;
}


const Tab = createBottomTabNavigator();

export default function App() {
  const [UID, setUID] = useState();
  const isSignedIn = getIsSignedIn();

  const getUID = () => {
    return UID;
  }

  function authListener(user) {
    if (user) {
      console.log("user signed in");
      setUID(user.uid);
    } else {
      console.log("signed out");
      setUID(null);
      console.log(UID);
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, authListener);
  }, []);

  return (
  <SafeAreaProvider>
    <NavigationContainer theme={DarkTheme}>
      {/* <Tab.Navigator screenOptions={{headerTitle: "Tasks Remaining"}}> */}
      <Tab.Navigator
        initialRouteName='Login'
        screenOptions={({ route }) => ({
        
          tabBarIcon: ({ focused, color, size }) => {
            let iconName;

            if (route.name === 'Tasks') {
              iconName = focused ? 'format-list-bulleted' : 'format-list-bulleted';
            } else if (route.name === 'Calendar') {
              iconName = focused ? 'calendar-check' : 'calendar-check-outline';
            } else if (route.name === 'Login') {
              iconName = focused ? 'account-circle' : 'account-circle-outline';
            } else if (route.name === 'Settings') {
              iconName = focused ? 'account-cog' : 'account-cog-outline';
            }

            // You can return any component that you like here!
            // return <Ionicons name={iconName} size={size} color={color} />;
            return <MaterialCommunityIcons name={iconName} size={size} color={color} />
          },
          tabBarActiveTintColor: '#bda57e',
          tabBarInactiveTintColor: 'gray',
          headerStyle: {
            backgroundColor: '#2B2A46',
          },
          headerTintColor: '#ffffff',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
          tabBarStyle: {
            backgroundColor: "#2b2b2a"
          }
        })}
      >
        {UID != null ? (
          <>
          {/* <Tab.Screen name="Tasks" component = {TaskScreen} options = {{title: auth.currentUser.displayName + "'s Tasks"}}/> */}
          <Tab.Screen name="Tasks" component = {TaskScreen} options = {{title: 'Tasks', headerShown: false}}/>
          <Tab.Screen name="Settings" component = {AccountSettings} options = {{title: 'Account Settings'}}/>
          </>
        ) : (
          <>
          <Tab.Screen name="Tasks" component = {TaskScreen} options = {{title: 'Tasks', headerShown: false}}/>
          <Tab.Screen name="Login" component={AuthScreen} options = {{title: 'Login'}}/>
          </>
        )}
      </Tab.Navigator>
    </NavigationContainer>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
