import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
// import { auth } from "firebaseConfig";
import { useState, useEffect } from "react";
import { View, TextInput, TouchableOpacity, Alert, Text, Button } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { onAuthStateChanged } from "firebase/auth";

export default function SignInScreen({navigation}) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);


    const onSignIn = () => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            Alert.alert("Signed In");
            navigation.navigate("Tasks");
            // ...
        })
        .catch((error) => {
            console.log("sign in failed");
            Alert.alert(error.code);
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }



    return ( 
        <View style={{flex: 1, backgroundColor: "#212120"}}>
                    <View style={{flex: 2}}></View>
                    <View style={{flex: 3, flexDirection: "column"}}>
                        <View style={{padding: 5}}>
                            <TextInput style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                                placeholder={"email"} value={email} onChangeText={setEmail}/>
                        </View>
                        <View style={{padding: 5}}>
                        <TextInput secureTextEntry={true} style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                            placeholder={"password"} value={password} onChangeText={setPassword}/>
                        </View>
                    </View>

                    <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                        <TouchableOpacity onPress={onSignIn} style={{position: "absolute", bottom: 20, backgroundColor: "#2b2b2a", borderRadius: 10, width: 200, height: 50, justifyContent: "center"}}>
                            <Text style={{color: "white", textAlign: "center", fontSize: 20, fontWeight: "500", fontFamily: ""}}>Sign In</Text>
                        </TouchableOpacity>
                    </View>
        
                    <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                        <TouchableOpacity onPress={() => navigation.navigate("SignUp")} style={{ borderRadius: 5, height: 30, width: 150, justifyContent: "center"}}>
                            <Text style={{color: "#bda57e", textAlign: "center", fontWeight: "500", fontSize: 15}}>Sign Up</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flex: 2}}></View>
        </View>
    );
    
}