import { Text, View } from 'react-native'
import React, { Component } from 'react'

export default class TaskItem extends Component {
  render() {
    return (
      <View>
        <Text>TaskItem</Text>
      </View>
    )
  }
}