import { View, Text, Alert, TouchableOpacity } from 'react-native'
import React from 'react'
import { signOut } from 'firebase/auth'
import { auth } from '../firebaseConfig';
import { useEffect } from 'react';
import { Button, StyleSheet } from 'react-native';

export default function AccountSettings() {

  const logOut = () => {
    signOut(auth);
    Alert.alert("Signed Out");
  }

  const user = auth.currentUser;

  useEffect(() => {
    console.log(user.email);
  }, []);

  return (
    <View style={{flex: 1, backgroundColor: '#212120'}}>

      <View style={{flex: 1}}>
        <View style={{flex: 1, paddingTop: 10, paddingLeft: 10}}>
            <Text style={styles.info}> Email: {user.email} </Text>
            <Text style={styles.info}> Name: {user.displayName} </Text>
        </View>
      </View>

      <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
        <TouchableOpacity onPress={logOut} style={{position: "absolute", bottom: 20, backgroundColor: "#2b2b2a", borderRadius: 10, width: 200, height: 50, justifyContent: "center"}}>
                                <Text style={{color: "white", textAlign: "center", fontSize: 20, fontWeight: "400", fontFamily: ""}}>Log Out</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  popup:{
      backgroundColor: "808080",
  },
  info:{
      fontSize: 20,
      color: "white",
      fontWeight: "bold",
      paddingTop: 10
  },
  newItem:{
      fontSize: 15,
      color: "white",
      opacity: 0.4
  }

}
);