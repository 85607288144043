import { View, Text } from 'react-native'
import React from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DarkTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SignInScreen from './SignInScreen';
import SignUpScreen from './SignUpScreen';
import TaskScreen from './TaskScreen';

const Stack = createNativeStackNavigator();


export default function AuthScreen() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false}}>
        <Stack.Screen name="SignIn" component={SignInScreen} options={{title: "Sign In"}}/>
        <Stack.Screen name="SignUp" component={SignUpScreen} options={{title: "Sign Up"}}/>
    </Stack.Navigator>
  )
}