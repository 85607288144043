import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../firebaseConfig";
// import { auth } from "firebaseConfig";
import { useState, useEffect } from "react";
import { View, TextInput, TouchableOpacity, Alert, Text, Modal } from "react-native";
import { Button, Icon } from "@rneui/base";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { onAuthStateChanged } from "firebase/auth";

export default function SignUpScreen( { navigation }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmedPassword] = useState("");
    const [username, setUsername] = useState("");

    const onSignUp = () => {
        if(password != confirmPassword) {
            console.log("Passwords do not match");
            return;
        }
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            updateProfile(userCredential.user, {
                displayName: username
            })
            console.log("Signed in");
            Alert.alert("Signed in as " + email);
            navigation.navigate("Tasks");
            // ...
        })
        .catch((error) => {
            Alert.alert(error.code, error.message);
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            // ..
        });
        setPassword();
    }



    return ( 
        <View style={{flex: 1, backgroundColor: "#212120"}}>
                    <View style={{flex: 2}}></View>
                    <View style={{flex: 3, flexDirection: "column"}}>

                        <View style={{padding: 5}}>
                            <TextInput style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                                placeholder={"name"} value={username} onChangeText={setUsername}/>
                        </View>

                        <View style={{padding: 5}}>
                            <TextInput style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                                placeholder={"email"} value={email} onChangeText={setEmail}/>
                        </View>

                        <View style={{padding: 5}}>
                            <TextInput secureTextEntry={true} style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                            placeholder={"password"} value={password} onChangeText={setPassword}/>
                        </View>

                        <View style={{padding: 5}}>
                            <TextInput secureTextEntry={true} style={{paddingLeft: 10, height: 50, color: "white", backgroundColor: "grey", borderWidth: 0, borderColor: "white", borderRadius: 10}} 
                                            placeholder={"confirm password"} value={confirmPassword} onChangeText={setConfirmedPassword}/>
                        </View>
                    </View>
                    {/* <Button title={"Sign Up"} style={{borderRadius: 20, height: 50}} onPress={onSignUp}/> */}
                    <View style={{flex: 1}}></View>
                    <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                        <TouchableOpacity onPress={onSignUp} style={{position: "absolute", bottom: 20, backgroundColor: "#2b2b2a", borderRadius: 10, width: 200, height: 50, justifyContent: "center"}}>
                                <Text style={{color: "white", textAlign: "center", fontSize: 20, fontWeight: "500", fontFamily: ""}}>Sign Up</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                        <TouchableOpacity onPress={() => navigation.navigate("SignIn")} style={{ borderRadius: 5, height: 30, width: 150, justifyContent: "center"}}>
                            <Text style={{color: "#bda57e", textAlign: "center", fontWeight: "500", fontSize: 15}}>Sign In</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flex: 2}}></View>
        </View>
    );
    
}