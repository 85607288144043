import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { initializeAuth } from 'firebase/auth';
// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyD7EN6n1zuPZqRL8aGxJF0wE5cHVQVX-DA",
    authDomain: "firestreamapp-65af3.firebaseapp.com",
    projectId: "firestreamapp-65af3",
    storageBucket: "firestreamapp-65af3.appspot.com",
    messagingSenderId: "318551448185",
    appId: "1:318551448185:web:a96324449d8fef9314d2a8",
    databaseURL: "https://firestreamapp-65af3-default-rtdb.firebaseio.com/"
  };

const app = initializeApp(firebaseConfig);
// initializeAuth(app, {
//   persistence: getReactNativePersistence(ReactNativeAsyncStorage)
// });
const auth = getAuth(app);
const db = getDatabase(app);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase

export { auth, db }
